import React, { useState, useEffect, useRef } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { ThemeProvider, createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import RecipeForm from './components/RecipeForm';
import RecipePage from './components/RecipePage';
import MyRecipesPage from './components/MyRecipesPage';
import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import HowItWorksSection from './components/HowItWorksSection';
import BenefitsSection from './components/BenefitsSection';
// import CTASection from './components/CTASection';
import FAQSection from './components/FAQSection';
import BrowseRecipes from './components/BrowseRecipes';
import AccountOverview from './components/AccountOverview';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Impressum from './pages/Impressum';
import SettingsPage from './pages/Settings';
import { getCurrentUser } from 'aws-amplify/auth';
import amplifyConfig from './config/aws-exports';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles
import { HelmetProvider } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
Amplify.configure(amplifyConfig);

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const recipeFormRef = useRef(null);

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  // Sync darkMode with system preference dynamically
  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      // primary: { main: darkMode ? '#90CAF9' : '#1976D2' },
      primary: { main: darkMode ? '#1976D2' : '#1976D2' },
      secondary: { main: darkMode ? '#81C784' : '#388E3C' },
      error: { main: '#D32F2F' },
      warning: { main: '#FFA726' },
      background: {
        default: darkMode ? '#121212' : '#F5F5F5',
        paper: darkMode ? '#1E1E1E' : '#FFFFFF',
      },
      text: {
        primary: darkMode ? '#FFFFFF' : '#000000',
        secondary: darkMode ? '#B0BEC5' : '#757575',
      },
    },
    typography: {
      h5: { fontWeight: 'bold', color: 'primary.main' },
      body1: { lineHeight: 1.6 },
      button: { textTransform: 'none', fontSize: '1rem', fontWeight: 600 },
    },
  });

  const handleScrollToRecipeForm = () => {
    recipeFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (err) {
        setUser(null);
      }
    };

    checkUser();

    const hubListener = Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'signedIn') {
        checkUser();
        toast.success(t('loginSuccess'));
      } else if (event === 'signedOut') {
        setUser(null);
        toast.success(t('signOutSuccess'));
      }
    });

    return () => hubListener();
  }, [t]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} user={user} setUser={setUser} />
          <main>
            <Routes>
              {/* Landing Page */}
              <Route
                path="/"
                element={
                  <>
                    <HeroSection onGetStarted={handleScrollToRecipeForm} />
                    <FeatureSection />
                    <HowItWorksSection />
                    <BenefitsSection />
                    {/* <CTASection onGetStarted={handleScrollToRecipeForm} /> */}
                    <div ref={recipeFormRef}>
                      <RecipeForm user={user} setUser={setUser} />
                    </div>
                    <AccountOverview onGetStarted={handleScrollToRecipeForm}/>
                    <FAQSection />
                  </>
                }
              />

              {/* Other Routes */}
              <Route path="/recipe/:id" element={<RecipePage />} />
              <Route path="/my-recipes" element={<MyRecipesPage />} />
              <Route path="/browse-recipes" element={<BrowseRecipes />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/settings" element={<SettingsPage />} />

              {/* Fallback Route */}
              <Route path="*" element={<div>Page not found</div>} />
            </Routes>
          </main>
          <Footer />
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
