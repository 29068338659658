import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const HeroSection = ({ onGetStarted })  => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: 'primary.main',
        color: 'white',
        // py: 8,
        // px: { xs: 3, md: 8 },
        padding: { xs: '20px', sm: '40px' }, // Adjust padding for responsiveness
        // backgroundColor: 'background.default', // Keep the background color
        // borderRadius: '8px',
      }}
    >
      {/* Text Section */}
      <Grid item md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
        <Typography
          variant={isMobile ? 'h5' : 'h3'} // Adjust font size for mobile
          sx={{
            fontWeight: 'bold',
            mb: isMobile ? 2 : 3, // Reduce margin for mobile
          }}
        >
          {t('hero.title', 'Personalized Recipes Just For You')}
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          {t(
            'hero.subtitle',
            'Discover recipes tailored to your dietary preferences and available ingredients, all powered by AI.'
          )}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={onGetStarted}
          sx={{
            borderRadius: '25px',
            px: 4,
            py: 1.5,
            fontWeight: 'bold',
            '&:hover': { backgroundColor: 'secondary.dark' },
          }}
        >
          {t('hero.cta', 'Get Started')}
        </Button>      
        <Typography variant="body1" sx={{ marginTop: 4 }}>
        {t('hero.or')} <Link to="/browse-recipes" style={{ color: '#fff', textDecoration: 'underline' }}>{t('hero.browseRecipes', 'browse all recipes')}</Link>
      </Typography>

      </Grid>

      {/* Image/Illustration Section */}
      <Grid item md={6} sx={{ mt: { xs: 4, md: 0 } }}>
        <Box
          component="img"
          src='/heroimage.jpeg'
          alt="Hero Illustration"
          sx={{
            maxWidth: '100%',
            borderRadius: '20px',
            boxShadow: 3,
          }}
        />
      </Grid>
    </Box>
  );
};

export default HeroSection;