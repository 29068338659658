import { IconButton } from '@mui/material';

const Icon = () => {
  return (
    <IconButton onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }} edge="start" color="inherit" aria-label="menu" sx={{ mr: 0 }}>
      <img src="/favicon.png" alt="SafePlates" style={{ width: '40px', height: '40px' }} />
      {/* <RestaurantMenuIcon /> */}
    </IconButton>
  );
};

export default Icon;