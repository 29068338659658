import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AccountOverview = ({ onGetStarted }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 8, mb: 8, p: 3 }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('accountOverview.title')}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', p: 3, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              {t('accountOverview.noAccount.title')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('accountOverview.noAccount.description')}
            </Typography>
            <Button variant="contained" color="primary" href="/browse-recipes">
              {t('accountOverview.noAccount.buttonText')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', p: 3, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              {t('accountOverview.freeAccount.title')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('accountOverview.freeAccount.description')}
            </Typography>
            <Button variant="contained" color="secondary" onClick={onGetStarted}>
              {t('accountOverview.freeAccount.buttonText')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountOverview;
