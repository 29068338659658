import React from 'react';
import { Box, Typography, Stack, Link, useTheme, useMediaQuery } from '@mui/material';
/* import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
} from '@mui/icons-material'; */
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        padding: '40px 20px',
        marginTop: '40px',
        textAlign: 'center',
      }}
    >
      {/* Social Media Links */}
      {/* <Stack direction="row" justifyContent="center" spacing={2} sx={{ marginBottom: '20px' }}>
        <IconButton
          component="a"
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'white' }}
          aria-label="Facebook"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'white' }}
          aria-label="Twitter"
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'white' }}
          aria-label="Instagram"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'white' }}
          aria-label="LinkedIn"
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          component="a"
          href="mailto:support@safeplates.de"
          sx={{ color: 'white' }}
          aria-label="Email"
        >
          <EmailIcon />
        </IconButton>
      </Stack> */}

      {/* Footer Links */}
      <Stack direction={isMobile ? "column" : "row"} justifyContent="center" spacing={2} sx={{ marginBottom: '20px' }}>
        <Link href="/privacy" color="inherit" underline="hover">
          {t('footer.privacyPolicy')} {/* "Privacy Policy" */}
        </Link>
        <Link href="/terms" color="inherit" underline="hover">
          {t('footer.termsOfService')} {/* "Terms of Service" */}
        </Link>
        <Link href="/impressum" color="inherit" underline="hover">
          {t('footer.impressum')} {/* "Impressum" */}
        </Link>
        <Link href="mailto:support@safeplates.de" color="inherit" underline="hover">
          {t('footer.contactUs')} {/* "Contact Us" */}
        </Link>
      </Stack>

      {/* Newsletter Signup */}
      {/* <Box component="form" sx={{ maxWidth: '500px', margin: '0 auto' }}>
        <Typography variant="body1" gutterBottom>
          {t('footer.newsletterTitle')}
        </Typography>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
          <TextField
            placeholder={t('footer.newsletterPlaceholder')} 
            variant="outlined"
            size="small"
            sx={{
              flex: 1,
              backgroundColor: 'white',
              borderRadius: '4px',
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          >
            {t('footer.subscribe')}
          </Button>
        </Stack>
      </Box> */}

      {/* Footer Bottom Text */}
      <Typography variant="caption" sx={{ marginTop: '20px', display: 'block' }}>
        © {new Date().getFullYear()} SafePlates. {t('footer.allRightsReserved')} {/* "All rights reserved." */}
      </Typography>
    </Box>
  );
};

export default Footer;
