import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import RecipeDisplay from '../components/RecipeDisplay'; // Ensure this path matches your project structure
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const apiUrl =
  process.env.REACT_APP_BACKEND_ENV === 'development'
    ? 'http://localhost:5000' // Local backend for testing
    : process.env.REACT_APP_API_URL; // Production API

const BrowseRecipes = () => {
  const { t, i18n } = useTranslation();
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/recipes/all`, {
          params: {
            language: i18n.language,
            limit: 25
          }
        });
        setRecipes(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching recipes:', error);
        setLoading(false);
      }
    };
    fetchRecipes();
  }, [i18n.language]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!recipes || recipes.length === 0) {
    return (
        <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('browseRecipes.title')}
        </Typography>
        <Box sx={{ mt: 4, mb: 4, borderBottom: '1px solid #e0e0e0' }}></Box>
      <Typography variant="h6" align="center" mt={4}>
        {t('browseRecipes.noRecipes')}
      </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('browseRecipes.title')}
      </Typography>
      <Box sx={{ mt: 4, mb: 4, borderBottom: '1px solid #e0e0e0' }}></Box>
      <Grid container spacing={2}>
        {recipes.map((recipe) => (
          <Grid item xs={12} sm={6} md={4} key={recipe.id}>
            <RecipeDisplay recipe={recipe} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            onClick={handleBack}
            sx={{
                borderRadius: '20px',
                padding: '10px 20px',
                background: 'linear-gradient(to right, #1976d2, #2196f3)',
                color: 'white',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                background: 'linear-gradient(to right, #2196f3, #1976d2)',
                },
            }}
            >
            {t('recipe.back')}
          </Button>
      </Box>
    </Box>
  );
};

export default BrowseRecipes;
