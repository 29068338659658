import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({ title }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mb: 0, marginTop: 2, marginLeft: 2 }}>
      <Typography component="nav" variant="body" sx={{
        color: 'grey.600', // Sets a more subdued color for the text
        '& a': {
          textDecoration: 'none',
          color: 'primary.main', // Use theme's primary color for links
          '&:hover': {
            textDecoration: 'underline', // Underline on hover for better UX
          }
        },
        '& > span': {
          mx: 0.5, // Margin left and right for the separator
          color: 'grey.500' // Lighter color for the separator
        }
      }}>
        <Link to="/" style={{color: 'inherit' }}>
          Home
        </Link>{' '}
        &gt;{' '}
        <Link to="/my-recipes" style={{color: 'inherit' }}>
          {t('myRecipes.title')}
        </Link>{' '}
        &gt; {title}
      </Typography>
    </Box>
  );
};

export default Breadcrumbs;