import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, CardContent, CardMedia, FormControlLabel, Switch } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Helmet } from 'react-helmet-async';
import { Button } from '@mui/material';
import SocialShare from './SocialShare';
import Breadcrumbs from './Breadcrumbs';
const apiUrl = process.env.REACT_APP_BACKEND_ENV === 'development' 
  ? 'http://localhost:5000'  // Local backend for testing
  : process.env.REACT_APP_API_URL; // Production API


const RecipePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visibility, setVisibility] = useState(false); // false = private, true = public

  const currentUrl = `${window.location.origin}/recipe/${id}`;

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        let token = null;
        try {
          const session = await fetchAuthSession();
          token = session?.tokens?.idToken?.toString();
        } catch (authError) {
          console.warn('User not authenticated:', authError);
        }
    
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
        const response = await axios.get(`${apiUrl}/api/recipes/${id}`, { headers });
        setRecipe(response.data);
        setVisibility(response.data.visibility === 'public');
      } catch (error) {
        if (error.response) {
          console.error('Error fetching recipe:', error.response.status, error.response.data);
        } else {
          console.error('Error fetching recipe:', error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchRecipe();
  }, [id, t]);

  const handleBack = () => {
    window.history.back();
  };

  const handlePrivacyToggle = async (event) => {
    const newStatus = event.target.checked ? "public" : "private";
    setVisibility(event.target.checked); // Update with boolean value
    try {
      let session = await fetchAuthSession();
      const token = session.tokens.idToken.toString();
      await axios.patch(`${apiUrl}/api/recipes/${id}/privacy`, { visibility: newStatus }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error updating privacy status:', error);
      setVisibility(!event.target.checked); // Revert state if the update fails
    }
  };

  console.log(recipe);

  if (loading) return <Typography>{t('loading')}</Typography>;
  if (!recipe) return <Typography>{t('recipe.notFound')}</Typography>;

  return (
    <div>
      <Helmet>
        <title>{recipe.title} | SafePlates</title>
        <meta name="description" content={recipe.instructions.substring(0, 150)} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:title" content={recipe.title} />
        <meta property="og:description" content={recipe.instructions.substring(0, 150)} />
        <meta property="og:image" content={recipe.image_url} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="SafePlates" />
        <meta property="og:locale" content={recipe.language==="en" ? "en_US" : "de_DE"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={recipe.title} />
        <meta property="twitter:description" content={recipe.instructions.substring(0, 150)} />
        <meta property="twitter:image" content={recipe.image_url} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Recipe",
            "name": recipe.title,
            "image": recipe.image_url,
            "video": recipe.image_url,
            "description": recipe.instructions.substring(0, 150),
            "author": {
              "@type": "Organization",
              "name": "SafePlates"
            },
            "prepTime": `PT${recipe.time || 0}M`,
            "recipeYield": recipe.servings,
            "nutrition": {
              "@type": "NutritionInformation",
              "calories": `${recipe.calories} kcal`,
            },
            "recipeInstructions": recipe.instructions,
            "recipeIngredient": recipe.instructions,
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingCount": 0,
              "ratingValue": 0
            },
            "keywords": recipe.dietary_restrictions,
            "cookTime": `PT${recipe.time || 0}M`,
            "recipeCategory": recipe.dietary_restrictions,
            "recipeCuisine": recipe.dish,
            "interactionStatistic": {
              "@type": "InteractionCounter",
              "interactionType": "https://schema.org/Comment",
              "userInteractionCount": `${recipe.views}`
            }
          })}
        </script>
      </Helmet>
      <Breadcrumbs title={recipe.title} />
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <Typography variant="h4">{recipe.title}</Typography>
        <FormControlLabel
            control={
              <Switch
                checked={visibility}
                onChange={handlePrivacyToggle}
                color="primary"
              />
            }
            label={visibility ? t('recipe.public') : t('recipe.private')}
          />
        </Box>
      <Typography variant="subtitle1">{t('recipe.aiGenerated')}</Typography>
      <Box sx={{ mt: 4, mb: 4, borderBottom: '1px solid #e0e0e0' }}></Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {recipe.image_url && (
            <CardMedia
            component="img"
            image={recipe.image_url}
            alt={`${recipe.title} image`}
            loading="lazy"
            sx={{ borderRadius: '8px', mb: 2, width: '100%', maxHeight: '500px', objectFit: 'contain', maxWidth: '800px' }}
            />
        )}
      </Box>
      <SocialShare recipe={recipe} visibility={visibility} onVisibilityChange={setVisibility}/>
      <Box sx={{ mt: 4, mb: 4, borderBottom: '1px solid #e0e0e0' }}></Box>
      <CardContent>
        <ReactMarkdown>{recipe.instructions}</ReactMarkdown>
        <Typography>{t('recipe.time')}: {recipe.time} {t('recipe.minutes')}</Typography>
          <Typography>{t('recipe.servings')}: {recipe.num_portions} {t('recipe.portions')}</Typography>
          <Typography>{t('recipe.calories')}: {recipe.calories} {t('recipe.kcal')}</Typography>
        </CardContent>
      </Box>

      <Box sx={{ mt: 4, mb: 4, borderBottom: '1px solid #e0e0e0' }}></Box>
      <Typography variant="body2" color="textSecondary" align="center">
        {t('recipe.disclaimer')}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {t('recipe.aiGeneratedDisclaimer')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <Button
        onClick={handleBack}
        sx={{
            borderRadius: '20px',
            padding: '10px 20px',
            background: 'linear-gradient(to right, #1976d2, #2196f3)',
            color: 'white',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            '&:hover': {
            background: 'linear-gradient(to right, #2196f3, #1976d2)',
            },
        }}
        >
        {t('recipe.back')}
      </Button>
      </Box>

    </div>
  );
};

export default RecipePage;
