import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Button } from '@mui/material';

const FeedbackButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' typically corresponds to tablets and upwards

  const handleFeedback = () => {
    window.location.href = "mailto:support@safeplates.de?subject=Feedback for SafePlates"; // This triggers the default mail client with a predefined subject
  };

  return (
    <Button onClick={handleFeedback} color="inherit" aria-label="feedback" sx={{ minHeight: 0, minWidth: 0, padding: 0.5, marginLeft: 1 }}>
      <FavoriteIcon sx={{ marginRight: 0.5 }} />
      {isMobile ? null : "Feedback"}
    </Button>
  );
};

export default FeedbackButton;
